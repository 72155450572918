import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Text,
  Select,
  Label,
} from "@theme-ui/components"
import ArticleThumb from "../components/articleThumb"
import SEO from "../components/seo"
import { getCategoryPath } from "../utils/path"
import { LocaleContext } from "../context/localeContext"
import { i18nContext, languages } from "../context/i18nContext"

const Category = ({ data: { category, articles }, location }) => {
  const allSlugLocales = category._allSlugLocales.map(page => {
    return {
      locale: page.locale,
      value: getCategoryPath(page.value, page.locale),
    }
  })

  const allArticles = articles.nodes
  const mainArticles = allArticles.filter(
    article => article.subCategory === null
  )
  const subArticles = allArticles.filter(
    article => article.subCategory !== null
  )
  const [filteredArticles, setFilteredArticles] = useState(subArticles)
  const categories = [...new Set(subArticles.map(a => a.subCategory.name))]

  function handleSelectChange(category) {
    if (category !== "all") {
      setFilteredArticles(
        subArticles.filter(obj => {
          return obj.subCategory.name === category
        })
      )
    } else {
      setFilteredArticles(subArticles)
    }
  }

  // console.log(category, articles, subArticles)

  return (
    <LocaleContext.Provider
      value={{ activeLocale: category.locale, paths: allSlugLocales }}
    >
      <i18nContext.Provider value={languages[category.locale]}>
        <i18nContext.Consumer>
          {t => (
            <Layout locale={category.locale} location={location}>
              <SEO lang={category.locale} title={category.name} />
              <Container>
                <Flex
                  sx={{
                    flexWrap: "wrap",
                    flexDirection: ["column", "column", "row"],
                  }}
                >
                  <Box
                    key={category.id}
                    sx={{
                      borderTop: "2px solid",
                      borderColor: "primary",
                      width: [
                        "100%",
                        "100%",
                        `${category.homePageItems * 25}%`,
                      ],
                    }}
                    pt={2}
                  >
                    <Box>
                      {!category.logo && (
                        <Text
                          as="h3"
                          sx={{
                            color: "primary",
                            fontSize: [3],
                            fontWeight: 500,
                          }}
                        >
                          {category.name}
                        </Text>
                      )}
                      {category.logo && (
                        <Image
                          sx={{ maxWidth: "180px", mb: 4 }}
                          src={category.logo.url}
                        />
                      )}
                      {category.description && (
                        <Box
                          sx={{ p: { fontSize: 2 }, mb: 4 }}
                          dangerouslySetInnerHTML={{
                            __html: category.description,
                          }}
                        />
                      )}
                    </Box>
                    <Grid columns={[1, 2, 3]}>
                      {mainArticles.map(article => (
                        <Box>
                          <ArticleThumb article={article} />
                        </Box>
                      ))}
                    </Grid>
                    {filteredArticles.length > 0 && (
                      <Box
                        sx={{
                          borderTop: "2px solid",
                          borderColor: "primary",
                          pt: 3,
                        }}
                      >
                        <Flex
                          sx={{
                            alignItems: "center",
                            flexDirection: "row",
                            "& > div": { flex: "1 1 100%" },
                            mb: 4,
                          }}
                        >
                          <Box>
                            <Label for="category" sx={{ mb: 2 }}>
                              {t.filterByCategory}
                            </Label>
                          </Box>
                          <Select
                            onChange={e => handleSelectChange(e.target.value)}
                            defaultValue="all"
                            id="category"
                          >
                            <option value="all">{t.allCategories}</option>
                            {categories.map(category => (
                              <option value={category}>{category}</option>
                            ))}
                          </Select>
                        </Flex>
                        <Grid columns={[1, 2, 3]}>
                          {filteredArticles.map(article => (
                            <Box>
                              <ArticleThumb article={article} />
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Flex>
              </Container>
            </Layout>
          )}
        </i18nContext.Consumer>
      </i18nContext.Provider>
    </LocaleContext.Provider>
  )
}

export default Category

export const query = graphql`
  query CategoryQuery($id: String!, $locale: String!) {
    category: datoCmsCategory(id: { eq: $id }) {
      id
      name
      description
      locale
      logo {
        url
      }
      _allSlugLocales {
        value
        locale
      }
    }
    articles: allDatoCmsArticle(
      filter: {
        category: { id: { eq: $id } }
        slug: { ne: null }
        locale: { eq: $locale }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        locale
        slug
        date(locale: $locale, formatString: "DD MMMM Y")
        category {
          name
        }
        subCategory {
          name
        }
        meta {
          firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
        }
        image {
          gatsbyImageData(
            width: 1230
            height: 820
            placeholder: BLURRED
            imgixParams: { fit: "crop" }
          )
        }
        structuredBody {
          value
          blocks {
            __typename
            ... on DatoCmsImage {
              id: originalId
              image {
                url
              }
            }
            ... on DatoCmsHighlightText {
              id: originalId
              title
              body
            }
          }
        }
      }
    }
  }
`
